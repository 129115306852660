import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PersonTagSchema } from '../person-tag/base';
import { PersonTagAscSchema } from '../person-tag-asc/base';
export const PersonGetTagsSchema = PersonTagSchema.extend({
  person_id: z.string(),
});

export type PersonGetTags = z.infer<typeof PersonGetTagsSchema>;

export const PersonGetTagsParamsSchema = z.object({
  person_id: z.string(),
});
export type PersonGetTagsParams = z.infer<typeof PersonGetTagsParamsSchema>;

export const PersonGetTagsResponseSchema = PersonTagAscSchema.extend({ name: z.string() });
export type PersonGetTagsResponse = z.infer<typeof PersonGetTagsResponseSchema>;

export type PersonGetTagsEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person/${PersonGetTagsParams['person_id']}/active-tags`,
  PersonGetTagsResponse,
  PersonGetTagsParams,
  undefined,
  undefined
>;
