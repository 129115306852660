import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure/endpoint';

// Placeholder schema - replace with actual schema if needed
const ResendOfferResponseSchema = z.object({});

export const ResendOfferParamsSchema = z.object({
  applicationId: z.string(),
});

export type ResendOfferEndpoint = Endpoint<
  Method.POST, // Method
  '/offer-management/:applicationId/resend-offer', // URL
  z.infer<typeof ResendOfferResponseSchema>, // Response
  z.infer<typeof ResendOfferParamsSchema>, // Params
  undefined, // Query
  undefined // Request
>;
