import { JobRegionAutoRejectionSchema } from '../base';
import { z } from 'zod';

export const JobRegionAutoRejectionFindSchema = JobRegionAutoRejectionSchema.pick({
  job_id: true,
  country_group_id: true,
  country_id: true,
  job_region_auto_rejection_id: true,
}).partial({
  country_group_id: true,
  country_id: true,
  job_region_auto_rejection_id: true,
  job_id: true,
});
export type JobRegionAutoRejectionFind = z.infer<typeof JobRegionAutoRejectionFindSchema>;
