import * as api from '@redux/api/application';
import { call, put, takeEvery } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import * as schemas from '@src/schemas';
import { normalize } from 'normalizr';
import { actions as entitiesActions } from '@redux/modules/entities';
import { message } from 'antd';
import isFunction from 'lodash/isFunction';

function* deleteApplication(action) {
  try {
    yield call(api.deleteApplication, action);
    yield put({ type: constants.APPLICATION_DELETE.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchDeleteApplication() {
  yield takeEvery(constants.APPLICATION_DELETE.REQUEST, deleteApplication);
}

function* reEnableOnboardingForm(action) {
  try {
    const response = yield call(api.reEnableOnboardingForm, action);
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
    const normalized = normalize(response.data.applicaiton, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));
    yield put(entitiesActions.mergeUsers(users));
    yield put({ type: constants.REENABLE_ONBOARDING_FORM.SUCCESS });

    message.success(response.message);
  } catch (e) {
    yield put({
      type: constants.REENABLE_ONBOARDING_FORM.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchReEnableOnboardingForm() {
  yield takeEvery(constants.REENABLE_ONBOARDING_FORM.REQUEST, reEnableOnboardingForm);
}
