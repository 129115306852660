import { z } from 'zod';
import { JobRegionAutoRejection, JobRegionAutoRejectionSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const JobRegionAutoRejectionDeleteSchema = JobRegionAutoRejectionSchema.pick({
  job_region_auto_rejection_id: true,
});
export type JobRegionAutoRejectionDelete = z.infer<typeof JobRegionAutoRejectionDeleteSchema>;

export type JobRegionAutoRejectionDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/job-region-auto-rejection`,
  JobRegionAutoRejection,
  JobRegionAutoRejectionDelete,
  undefined,
  undefined
>;
