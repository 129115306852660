import { z } from 'zod';

export const JobRegionAutoRejectionSchema = z.object({
  job_region_auto_rejection_id: z.number(),
  job_id: z.string(),
  country_id: z.string().nullable(),
  country_group_id: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type JobRegionAutoRejection = z.infer<typeof JobRegionAutoRejectionSchema>;

export const JobAutoRejectedCountriesSchema = z.object({
  job_id: z.string(),
  countries_id: z.array(z.string()),
});

export type JobAutoRejectedCountries = z.infer<typeof JobAutoRejectedCountriesSchema>;
