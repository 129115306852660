import { Endpoint, Method } from '../../../infrastructure';
import { JobRegionAutoRejection, JobRegionAutoRejectionSchema } from '../base';
import { z } from 'zod';

export const JobRegionAutoRejectionUpsertSchema = JobRegionAutoRejectionSchema.pick({
  job_id: true,
  country_group_id: true,
  country_id: true,
}).partial({
  country_group_id: true,
  country_id: true,
});
export type JobRegionAutoRejectionUpsert = z.infer<typeof JobRegionAutoRejectionUpsertSchema>;

export type JobRegionAutoRejectionUpsertEndpoint = Endpoint<
  Method.POST,
  `/api/v2/job-region-auto-rejection`,
  JobRegionAutoRejection,
  undefined,
  undefined,
  JobRegionAutoRejectionUpsert
>;
