import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure/endpoint';
import { OfferStates } from '../base';

const GetOfferStateResponseSchema = z.object({
  currentState: z.object({
    name: z.enum(OfferStates),
    created_at: z.string().nullable(),
    completed_at: z.string().nullable(),
  }),
  previousStates: z
    .object({
      name: z.enum(OfferStates),
      created_at: z.string().nullable(),
      completed_at: z.string().nullable(),
    })
    .array(),
});

export const GetOfferStateParamsSchema = z.object({
  applicationId: z.string(),
});

export type GetOfferStateEndpoint = Endpoint<
  Method.GET, // Method
  '/offer-management/:applicationId/state',
  z.infer<typeof GetOfferStateResponseSchema>, // Response
  z.infer<typeof GetOfferStateParamsSchema>, // Params
  undefined, // Query
  undefined // Request
>;
